import PostLayout from 'components/PostLayout'
import { graphql } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { SEO } from 'components/seo'
import { SEO2 } from 'components/seo2'
import Layout from 'components/Layout'
import { Posts, PostToggle } from 'components/Blog'
import Pagination from 'components/Pagination'
import { NewsletterForm } from 'components/NewsletterForm'
import SupportCTA from 'components/SupportCTA'
import ArrayCTA from 'components/ArrayCTA'
import { capitalize } from 'instantsearch.js/es/lib/utils'
import { useLayoutData } from 'hooks/useLayoutData'
import { getDirection, removeLanguagePrefix } from 'utils/translations'
const BlogTag = ({
    data: {
        allPostsRecent: { edges: allPostsRecent },
        allPostsPopular: { edges: allPostsPopular },
    },
    pageContext: { tag, numPages, currentPage, base, menu, brand, locale },
}) => {
    const cleanedBase = removeLanguagePrefix(base, 'ar')
    const dir = getDirection(locale)
    const [allPostsFilter, setAllPostsFilter] = useState<'latest' | 'popular'>('latest')
    const handleToggleChange = (checked: boolean) => {
        const postsFilter = checked ? 'popular' : 'latest'
        localStorage.setItem('postsFilter', postsFilter)
        setAllPostsFilter(postsFilter)
    }

    useEffect(() => {
        setAllPostsFilter(localStorage?.getItem('postsFilter') || 'latest')
    }, [])

    const posts = allPostsFilter === 'popular' ? allPostsPopular : allPostsRecent
    return (
        <Layout>
            <SEO title={`${tag} - ${brand}`} />

            <PostLayout dir={dir} article={false} title="Blog" menu={menu} hideSidebar hideSurvey>
                <Posts
                    titleBorder
                    title={tag}
                    posts={posts?.slice(0, 4)}
                    action={<PostToggle checked={allPostsFilter === 'popular'} onChange={handleToggleChange} />}
                />
                <NewsletterForm />
                <Posts posts={posts?.slice(4, 12)} />
                {posts.length > 12 && (
                    <>
                        <ArrayCTA />
                        <Posts posts={posts?.slice(12)} />
                    </>
                )}
                <Pagination currentPage={currentPage} numPages={numPages} base={cleanedBase} />
            </PostLayout>
        </Layout>
    )
}

export default BlogTag

export const pageQuery = graphql`
    query ($skip: Int!, $limit: Int!, $tag: String, $locale: String!) {
        allPostsRecent: allMdx(
            limit: $limit
            skip: $skip
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: {
                isFuture: { eq: false }
                frontmatter: { locale: { eq: $locale }, tags: { in: [$tag] }, date: { ne: null } }
            }
        ) {
            edges {
                node {
                    ...BlogFragment
                }
            }
        }
        allPostsPopular: allMdx(
            limit: $limit
            skip: $skip
            sort: { order: DESC, fields: [fields___pageViews] }
            filter: {
                isFuture: { eq: false }
                frontmatter: { locale: { eq: $locale }, tags: { in: [$tag] }, date: { ne: null } }
            }
        ) {
            edges {
                node {
                    ...BlogFragment
                }
            }
        }
    }
`
